import React, { useState } from 'react'; 
import './FlashcardCell.css'; // Asegúrate de tener el archivo con el estilo flashcard

const heartSoundsData = [
  {
    id: 'S1',
    label: 'S1',
    line: 'sistole',
    position: '20%', 
    info: 'Cierre de válvulas AV (marca el inicio de la sístole)'
  },
  {
    id: 'S2',
    label: 'S2',
    line: 'diastole',
    position: '0%',
    info: 'Cierre de válvulas semilunares (fin de la sístole, inicio de la diástole)'
  },
  {
    id: 'S3',
    label: 'S3',
    line: 'diastole',
    position: '40%',
    info: 'Llenado rápido ventricular (diástole temprana, justo después de S2)'
  },
  {
    id: 'S4',
    label: 'S4',
    line: 'diastole',
    position: '90%',
    info: 'Contracción auricular (justo antes de S1, fin de la diástole)'
  }
];

// Nueva tabla basada en la imagen proporcionada
const tableData = [
  {
    ruido: 'S1',
    momento: 'Inicio sístole',
    significado: 'Cierre válvulas AV',
  },
  {
    ruido: 'S2',
    momento: 'Fin sístole, inicio diástole',
    significado: 'Cierre válvulas semilunares',
  },
  {
    ruido: 'S3',
    momento: 'Diástole temprana',
    significado: 'Llenado rápido ventricular',
  },
  {
    ruido: 'S4',
    momento: 'Fin diástole, antes de S1',
    significado: 'Contracción auricular',
  },
];

const columnDisplayNames = {
  ruido: 'Ruido',
  momento: 'Momento',
  significado: 'Significado',
};

const columns = Object.keys(columnDisplayNames);

const EsquemaRuidosCardiacos = () => {
  // Estados para el esquema lineal
  const [hiddenMarkers, setHiddenMarkers] = useState({});
  const [revealedText, setRevealedText] = useState('');

  // Estados de la tabla
  const [expandedRows, setExpandedRows] = useState([]);
  const [hiddenCells, setHiddenCells] = useState({});
  const [hiddenColumns, setHiddenColumns] = useState({});

  // Manejo de marcadores del esquema
  const toggleMarker = (markerId) => {
    setHiddenMarkers(prev => ({
      ...prev,
      [markerId]: !prev[markerId]
    }));
  };

  const toggleAllMarkers = () => {
    const allHidden = Object.keys(hiddenMarkers).length === 0 ? false : true;
    if (!allHidden) {
      const newHidden = {};
      heartSoundsData.forEach((m) => {
        newHidden[m.id] = true;
      });
      setHiddenMarkers(newHidden);
    } else {
      setHiddenMarkers({});
    }
  };

  const toggleMarkerFlashcard = (marker) => {
    if (hiddenMarkers[marker.id]) return;
    const cellKey = `marker-${marker.id}`;
    setHiddenCells((prev) => ({
      ...prev,
      [cellKey]: !prev[cellKey],
    }));
  };

  const handleMarkerRevealText = (marker) => {
    const text = `${marker.label}: ${marker.info}`;
    if (revealedText === text) {
      setRevealedText('');
    } else {
      setRevealedText(text);
    }

    const cellKey = `marker-${marker.id}`;
    setHiddenCells((prev) => {
      const newState = { ...prev };
      if (newState[cellKey]) {
        delete newState[cellKey];
      }
      return newState;
    });
  };

  // Funciones de la tabla
  const toggleRow = (index) => {
    setExpandedRows((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const toggleColumn = (columnKey) => {
    setHiddenColumns((prevState) => {
      const newHiddenColumns = {
        ...prevState,
        [columnKey]: !prevState[columnKey],
      };

      const newHiddenCells = { ...hiddenCells };
      tableData.forEach((item, idx) => {
        const cellKey = `${idx}-${columnKey}`;
        if (newHiddenColumns[columnKey]) {
          newHiddenCells[cellKey] = true;
        } else {
          delete newHiddenCells[cellKey];
        }
      });

      setHiddenCells(newHiddenCells);
      return newHiddenColumns;
    });
  };

  const toggleCell = (rowIdx, fieldKey) => {
    const cellKey = `${rowIdx}-${fieldKey}`;
    setHiddenCells((prevState) => ({
      ...prevState,
      [cellKey]: !prevState[cellKey],
    }));
  };

  const toggleAllCells = () => {
    const areAllCellsVisible = Object.keys(hiddenCells).length === 0;
    if (areAllCellsVisible) {
      const newHiddenCells = {};
      tableData.forEach((item, idx) => {
        columns.forEach((colKey) => {
          if (colKey !== 'ruido') {
            const cellKey = `${idx}-${colKey}`;
            newHiddenCells[cellKey] = true;
          }
        });
      });
      setHiddenCells(newHiddenCells);
    } else {
      setHiddenCells({});
    }
  };

  const handleRevealText = (text, rowIdx, fieldKey) => {
    if (revealedText === text) {
      setRevealedText('');
    } else {
      setRevealedText(text);
    }

    const cellKey = `${rowIdx}-${fieldKey}`;
    setHiddenCells((prevHiddenCells) => {
      const newState = { ...prevHiddenCells };
      if (newState[cellKey]) {
        delete newState[cellKey];
      }
      return newState;
    });
  };

  const sistoleSounds = heartSoundsData.filter(s => s.line === 'sistole');
  const diastoleSounds = heartSoundsData.filter(s => s.line === 'diastole');

  return (
    <div className="font-sans text-gray-800 p-6 min-h-screen bg-gradient-to-tr from-blue-50 to-blue-100">
      <div className="max-w-3xl mx-auto text-center">
        <h2 className="font-extrabold text-3xl text-blue-800 mb-3">Esquema del Ciclo Cardíaco</h2>
        <p className="text-blue-700 font-medium">
          Visualiza la ubicación de los ruidos cardiacos a lo largo de la sístole y la diástole.
        </p>
        
        <div className="flex justify-center items-center text-blue-700 font-bold text-lg mb-4 mt-8">
          <span className="mr-2">SÍSTOLE</span>
          <span className="ml-2">DIÁSTOLE</span>
        </div>
  
        <div className="font-semibold text-gray-600 mb-6">
          Ruidos: 
          <span className="mx-2 text-blue-700">S1</span>
          <span className="mx-2 text-blue-700">S2</span>
          <span className="mx-2 text-blue-700">S3</span>
          <span className="mx-2 text-blue-700">S4</span>
        </div>

        {/* Controles para el esquema */}
        <div className="mb-4 text-center">
          <span className="font-bold mr-2">Mostrar/Ocultar Marcadores:</span>
          {heartSoundsData.map((m) => (
            <button
              key={m.id}
              onClick={() => toggleMarker(m.id)}
              className="mr-2 mb-2 px-3 py-1 rounded bg-blue-500 text-white hover:bg-blue-600 transition font-semibold"
            >
              {hiddenMarkers[m.id] ? `Mostrar ${m.label}` : `Ocultar ${m.label}`}
            </button>
          ))}
          <button
            onClick={toggleAllMarkers}
            className="mr-2 mb-2 px-3 py-1 rounded bg-green-600 text-white hover:bg-green-700 transition font-semibold"
          >
            {Object.keys(hiddenMarkers).length === 0 ? 'Ocultar todos los marcadores' : 'Mostrar todos los marcadores'}
          </button>
        </div>
  
        {/* Esquema lineal */}
        <div className="flex justify-center items-center relative mb-12 mt-8">
          {/* Línea de sístole */}
          <div className="relative h-8 w-36 border-b-4 border-blue-500">
            {sistoleSounds.map(sound => {
              const cellKey = `marker-${sound.id}`;
              const flipped = hiddenCells[cellKey] ? 'flipped' : '';
              return !hiddenMarkers[sound.id] && (
                <div
                  key={sound.id}
                  className={`absolute flashcard-cell text-sm font-bold rounded-full border border-gray-300 bg-white px-3 py-1 cursor-pointer hover:bg-blue-100 hover:shadow-lg transition-shadow duration-200 ${flipped}`}
                  style={{ top: '-2rem', left: sound.position, transform: 'translateX(-50%)' }}
                  onClick={() => toggleMarkerFlashcard(sound)}
                >
                  <div className="flashcard-cell-inner">
                    <div className="flashcard-cell-front rounded-md shadow-md bg-white p-2 text-center">
                      {sound.label}
                    </div>
                    <div
                      className="flashcard-cell-back rounded-md shadow-md bg-gray-50 p-2 text-center"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <button
                        className="text-blue-600 underline font-semibold hover:text-blue-800 mb-1 block"
                        onClick={() => handleMarkerRevealText(sound)}
                      >
                        {revealedText === `${sound.label}: ${sound.info}` ? 'Ocultar' : 'Mostrar'}
                      </button>
                      <p className="text-sm text-gray-700">
                        {revealedText === `${sound.label}: ${sound.info}` ? `${sound.info}` : ''}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
  
          {/* Separador vertical */}
          <div className="w-0.5 h-8 bg-blue-500 mx-4"></div>
  
          {/* Línea de diástole */}
          <div className="relative h-8 w-64 border-b-4 border-blue-500">
            {diastoleSounds.map(sound => {
              const cellKey = `marker-${sound.id}`;
              const flipped = hiddenCells[cellKey] ? 'flipped' : '';
              return !hiddenMarkers[sound.id] && (
                <div
                  key={sound.id}
                  className={`absolute flashcard-cell text-sm font-bold rounded-full border border-gray-300 bg-white px-3 py-1 cursor-pointer hover:bg-blue-100 hover:shadow-lg transition-shadow duration-200 ${flipped}`}
                  style={{ top: '-2rem', left: sound.position, transform: 'translateX(-50%)' }}
                  onClick={() => toggleMarkerFlashcard(sound)}
                >
                  <div className="flashcard-cell-inner">
                    <div className="flashcard-cell-front rounded-md shadow-md bg-white p-2 text-center">
                      {sound.label}
                    </div>
                    <div
                      className="flashcard-cell-back rounded-md shadow-md bg-gray-50 p-2 text-center"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <button
                        className="text-blue-600 underline font-semibold hover:text-blue-800 mb-1 block"
                        onClick={() => handleMarkerRevealText(sound)}
                      >
                        {revealedText === `${sound.label}: ${sound.info}` ? 'Ocultar' : 'Mostrar'}
                      </button>
                      <p className="text-sm text-gray-700">
                        {revealedText === `${sound.label}: ${sound.info}` ? `${sound.info}` : ''}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
  
        <div className="mt-10 text-sm font-medium text-gray-700">
          <span className="mr-5">S1: Cierre AV</span>
          <span className="mr-5">S2: Cierre SL</span>
          <span className="mr-5">S3: Llenado rápido</span>
          <span className="mr-5">S4: Contracción auricular</span>
        </div>

        {/* Controles y tabla interactiva */}
        <div className="overflow-x-auto mt-12 p-4 bg-white shadow-lg rounded-md">
          <h4 className="font-bold text-blue-800 mb-4 text-center text-2xl">
            Tabla Resumen de los Ruidos Cardíacos
          </h4>

          <div className="mb-4 text-center">
            <button
              onClick={toggleAllCells}
              className="mb-2 px-3 py-1 rounded bg-green-600 text-white hover:bg-green-700 transition font-semibold"
            >
              {Object.keys(hiddenCells).length === 0
                ? 'Ocultar toda la información'
                : 'Mostrar toda la información'}
            </button>
          </div>

          <div className="mb-6 text-center">
            <span className="font-bold mr-2">Mostrar/Ocultar Columnas:</span>
            {columns.map((columnKey) => (
              <button
                key={columnKey}
                onClick={() => toggleColumn(columnKey)}
                className="mr-2 mb-2 px-3 py-1 rounded bg-blue-500 text-white hover:bg-blue-600 transition font-semibold"
              >
                {hiddenColumns[columnKey]
                  ? `Mostrar ${columnDisplayNames[columnKey]}`
                  : `Ocultar ${columnDisplayNames[columnKey]}`}
              </button>
            ))}
          </div>

          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="border-b-2 border-gray-300 bg-blue-100 text-center p-3 font-semibold">Acción</th>
                {columns.map((columnKey) => (
                  !hiddenColumns[columnKey] && (
                    <th
                      key={columnKey}
                      className="border-b-2 border-gray-300 bg-blue-100 text-center p-3 font-semibold text-gray-700"
                      style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    >
                      {columnDisplayNames[columnKey]}
                    </th>
                  )
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {tableData.map((item, idx) => (
                <React.Fragment key={idx}>
                  <tr className="transition duration-200 hover:bg-gray-50">
                    <td className="p-3 align-top text-center border-b border-gray-200">
                      <button
                        onClick={() => toggleRow(idx)}
                        className="text-blue-600 underline font-semibold hover:text-blue-800"
                      >
                        {expandedRows.includes(idx) ? 'Ocultar' : 'Mostrar'}
                      </button>
                    </td>
                    {columns.map((colKey) => {
                      if (hiddenColumns[colKey]) return null;
                      const cellKey = `${idx}-${colKey}`;
                      const flipped = hiddenCells[cellKey] ? 'flipped' : '';
                      const cellValue = item[colKey];
                      return (
                        <td
                          key={colKey}
                          className="p-3 align-top border-b border-gray-200"
                          style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                        >
                          <div className={`flashcard-cell ${flipped}`}>
                            <div className="flashcard-cell-inner">
                              <div
                                className="flashcard-cell-front rounded-md shadow-md bg-white p-3 cursor-pointer"
                                onClick={() => toggleCell(idx, colKey)}
                              >
                                {cellValue}
                              </div>
                              <div
                                className="flashcard-cell-back rounded-md shadow-md bg-gray-50 p-3"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <button
                                  className="text-blue-600 underline font-semibold hover:text-blue-800"
                                  onClick={() =>
                                    handleRevealText(cellValue, idx, colKey)
                                  }
                                >
                                  {revealedText === cellValue ? 'Ocultar' : 'Mostrar'}
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      );
                    })}
                  </tr>

                  {expandedRows.includes(idx) && (
                    <tr className="transition duration-200 bg-gray-50">
                      <td className="p-3 border-b border-gray-200"></td>
                      <td
                        className="p-3 border-b border-gray-200"
                        colSpan={columns.filter((c) => !hiddenColumns[c]).length}
                        style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                      >
                        <strong>Detalles Adicionales:</strong>
                        <p className="mt-2">No hay detalles adicionales.</p>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>

          {revealedText && (
            <div className="mt-6 p-4 border border-gray-300 bg-gray-100 rounded-md shadow-sm">
              <h5 className="font-bold mb-2 text-gray-800">Texto Mostrado:</h5>
              <p className="text-gray-700">{revealedText}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EsquemaRuidosCardiacos;