import React, { useEffect, useState } from 'react';
// Importaciones originales...
import Quiz from './components/Quiz';
import DecisionTable from './components/DecisionTable';
import HeartFailureTable from './components/HeartFailureTable';
import HeartFailureTable1 from './components/HeartFailureTable1';
import RuidosCardiacosTable from './components/Tablaruidoscardiacos';
import EsquemaRuidosCardiacos from './components/Esquemaruidoscardiacos';

// ***** NUEVA IMPORTACIÓN *****
import Tabladeruidoscardiacos2 from './components/Tablaruidoscardiacos2';

import netlifyIdentity from 'netlify-identity-widget';

import emergenciasHipertensivas from './data/cardiologia/emergenciasHipertensivas';
import arritmiasCases from './data/cardiologia/arritmias';
import insuficienciacardiaCases from './data/cardiologia/insuficienciaCardiaca';
import ruidosCardiacoscases from './data/cardiologia/ruidosCardiacos';
import accidenteCerebrovascularCases from './data/neurologia/accidenteCerebrovascular';

function App() {
  // Estado para el usuario (login).
  const [user, setUser] = useState(
    process.env.NODE_ENV === 'development'
      ? {
          user_metadata: { full_name: 'Usuario Local' },
          email: 'local@example.com',
        }
      : null
  );

  // Estados para manejo de selección de Área Médica y Tema.
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);

  // Estados para mostrar/ocultar componentes específicos
  const [showHeartFailureTable, setShowHeartFailureTable] = useState(false);
  const [showHeartFailureTable1, setShowHeartFailureTable1] = useState(false);
  const [showRuidosCardiacosTable, setShowRuidosCardiacosTable] = useState(false);
  const [showEsquemaRuidosCardiacos, setShowEsquemaRuidosCardiacos] = useState(false);
  // ***** NUEVO ESTADO PARA MOSTRAR/OCULTAR Tabladeruidoscardiacos2 *****
  const [showRuidosCardiacosTable2, setShowRuidosCardiacosTable2] = useState(false);

  // Estados para el modo de simulación de preguntas.
  const [simulationMode, setSimulationMode] = useState(false);
  const [simulationTime, setSimulationTime] = useState(3);

  // Efecto para inicializar Netlify Identity (solo cuando no estamos en desarrollo).
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      netlifyIdentity.init();
      netlifyIdentity.on('login', (user) => {
        setUser(user);
        netlifyIdentity.close();
      });
      netlifyIdentity.on('logout', () => setUser(null));
    }
  }, []);

  // Función de login.
  const handleLogin = () => {
    if (process.env.NODE_ENV !== 'development') {
      netlifyIdentity.open();
    }
  };

  // Datos de las áreas médicas y sus temas.
  const subjects = [
    {
      id: 'cardiologia',
      name: 'Cardiología',
      topics: [
        {
          id: 'emergenciasHipertensivas',
          name: 'Emergencias Hipertensivas',
          questions: emergenciasHipertensivas,
          DecisionTableComponents: [DecisionTable],
        },
        {
          id: 'arritmias',
          name: 'Arritmias',
          questions: arritmiasCases,
        },
        {
          id: 'insuficienciacardiaca',
          name: 'Insuficiencia Cardiaca',
          questions: insuficienciacardiaCases,
        },
        {
          id: 'ruidosCardiacos',
          name: 'Ruidos Cardiacos',
          questions: ruidosCardiacoscases,
        },
      ],
    },
    {
      id: 'neurologia',
      name: 'Neurología',
      topics: [
        {
          id: 'accidenteCerebrovascular',
          name: 'Accidente Cerebrovascular',
          questions: accidenteCerebrovascularCases,
        },
      ],
    },
  ];

  // Si no hay usuario y no estamos en modo desarrollo, forzamos el login.
  if (!user && process.env.NODE_ENV !== 'development') {
    return (
      <div className="App min-h-screen flex flex-col items-center justify-center bg-gray-100">
        <h1 className="text-3xl font-bold mb-4">Debes iniciar sesión</h1>
        <button
          onClick={handleLogin}
          className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition"
        >
          Ingresar
        </button>
      </div>
    );
  }

  // Si no hay Área Médica seleccionada, muestra el menú de áreas.
  if (!selectedSubject) {
    return (
      <div className="min-h-screen bg-gray-100 py-8">
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
          <h1 className="text-2xl font-bold text-center text-gray-800 mb-6">
            {process.env.NODE_ENV === 'development'
              ? 'Bienvenido Alexis'
              : `Bienvenido ${user?.user_metadata?.full_name || ''}`}
          </h1>
          <p className="mb-4">Correo: {user?.email || 'no disponible'}</p>
          {process.env.NODE_ENV !== 'development' && (
            <button
              onClick={() => netlifyIdentity.logout()}
              className="mb-4 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition"
            >
              Salir
            </button>
          )}
          <h2 className="text-xl font-bold text-gray-800 mb-6 text-center">
            Selecciona un Área Médica
          </h2>
          <div className="flex flex-col space-y-4">
            {subjects.map((subject) => (
              <button
                key={subject.id}
                onClick={() => setSelectedSubject(subject)}
                className="w-full p-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition font-semibold"
              >
                {subject.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  }

  // Si no hay Tema seleccionado, muestra el menú de temas para esa área.
  if (!selectedTopic) {
    return (
      <div className="min-h-screen bg-gray-100 py-8">
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
          <button
            onClick={() => {
              setSelectedSubject(null);
              setSimulationMode(false);
            }}
            className="mb-4 px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition font-semibold"
          >
            Volver al Menú de Áreas
          </button>
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
            Selecciona un Tema de {selectedSubject.name}
          </h2>

          <div className="mb-4 text-center">
            <div className="flex flex-col items-center space-y-2 mb-4">
              <label className="text-sm font-medium text-gray-700">
                Duración del Simulacro (minutos):
              </label>
              <input
                type="number"
                value={simulationTime}
                onChange={(e) => setSimulationTime(Number(e.target.value))}
                className="w-20 p-2 border border-gray-300 rounded text-center"
                min="1"
              />
            </div>
            <button
              onClick={() => setSimulationMode(!simulationMode)}
              className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition font-semibold"
            >
              {simulationMode ? 'Desactivar Modo Simulacro' : 'Activar Modo Simulacro'}
            </button>
            <p className="mt-2 text-sm text-gray-600">
              {simulationMode
                ? `Modo simulacro activado: Preguntas aleatorias, temporizador de ${simulationTime} minutos.`
                : 'Modo simulacro desactivado: Sin límite de tiempo, preguntas en orden original.'}
            </p>
          </div>

          <div className="flex flex-col space-y-4">
            {selectedSubject.topics.map((topic) => (
              <button
                key={topic.id}
                onClick={() => setSelectedTopic(topic)}
                className="w-full p-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition font-semibold"
              >
                {topic.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  }

  // Función para volver al menú de temas y reestablecer valores de las tablas.
  const handleBackToTopics = () => {
    setShowHeartFailureTable(false);
    setShowHeartFailureTable1(false);
    setShowRuidosCardiacosTable(false);
    setShowEsquemaRuidosCardiacos(false);

    // ***** REINICIAMOS el NUEVO ESTADO *****
    setShowRuidosCardiacosTable2(false);

    setSelectedTopic(null);
    setSimulationMode(false);
  };

  return (
    <div className="min-h-screen bg-gray-100 py-8">
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
        <button
          onClick={handleBackToTopics}
          className="mb-4 px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition font-semibold"
        >
          Volver al Menú de Temas
        </button>

        {/* Componente principal de preguntas */}
        <Quiz
          title={selectedTopic.name}
          questions={selectedTopic.questions}
          onBack={() => setSelectedTopic(null)}
          DecisionTableComponents={selectedTopic.DecisionTableComponents}
          simulationMode={simulationMode}
          subjectName={selectedSubject.name}
          topicName={selectedTopic.name}
          simulationTime={simulationTime}
        />

        {selectedTopic.id === 'ruidosCardiacos' && (
          <div className="mt-6 space-x-4">
            <button
              onClick={() => setShowRuidosCardiacosTable((prev) => !prev)}
              className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition font-semibold"
            >
              {showRuidosCardiacosTable
                ? 'Ocultar Tabla de Ruidos Cardiacos'
                : 'Mostrar Tabla de Ruidos Cardiacos'}
            </button>

            <button
              onClick={() => setShowEsquemaRuidosCardiacos((prev) => !prev)}
              className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition font-semibold"
            >
              {showEsquemaRuidosCardiacos
                ? 'Ocultar Esquema de Ruidos Cardíacos'
                : 'Mostrar Esquema de Ruidos Cardíacos'}
            </button>

            {/* ***** BOTÓN PARA MOSTRAR/OCULTAR Tabladeruidoscardiacos2 ***** */}
            <button
              onClick={() => setShowRuidosCardiacosTable2((prev) => !prev)}
              className="px-4 py-2 bg-pink-600 text-white rounded-lg hover:bg-pink-700 transition font-semibold"
            >
              {showRuidosCardiacosTable2
                ? 'Ocultar Tabladeruidoscardiacos2'
                : 'Mostrar Tabladeruidoscardiacos2'}
            </button>
          </div>
        )}

        {/* Tabla RuidosCardiacos original */}
        {showRuidosCardiacosTable && (
          <div className="mt-6">
            <RuidosCardiacosTable />
          </div>
        )}

        {/* Esquema RuidosCardiacos */}
        {showEsquemaRuidosCardiacos && (
          <div className="mt-6">
            <EsquemaRuidosCardiacos />
          </div>
        )}

        {/* ***** RENDERIZAR EL NUEVO COMPONENTE Tabladeruidoscardiacos2 ***** */}
        {showRuidosCardiacosTable2 && (
          <div className="mt-6">
            <Tabladeruidoscardiacos2 />
          </div>
        )}

        {/* Botones y componentes para Insuficiencia Cardíaca */}
        {selectedTopic.id === 'insuficienciacardiaca' && (
          <div className="mt-6 space-x-4">
            <button
              onClick={() => setShowHeartFailureTable((prev) => !prev)}
              className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition font-semibold"
            >
              {showHeartFailureTable ? 'Ocultar HeartFailureTable' : 'Mostrar HeartFailureTable'}
            </button>

            <button
              onClick={() => setShowHeartFailureTable1((prev) => !prev)}
              className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition font-semibold"
            >
              {showHeartFailureTable1 ? 'Ocultar HeartFailureTable1' : 'Mostrar HeartFailureTable1'}
            </button>
          </div>
        )}

        {showHeartFailureTable && (
          <div className="mt-6">
            <HeartFailureTable />
          </div>
        )}

        {showHeartFailureTable1 && (
          <div className="mt-6">
            <HeartFailureTable1 />
          </div>
        )}
      </div>
    </div>
  );
}

export default App;