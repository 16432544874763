import React, { useState, useEffect } from 'react'; 
import { BookOpen, Check, X } from 'lucide-react';

function Quiz({
  title,
  questions,
  onBack,
  DecisionTableComponents,
  simulationMode,
  subjectName,
  topicName,
  simulationTime
}) {
  // Manejo de tiempo (simulación)
  const totalTimeInSeconds = simulationMode ? simulationTime * 60 : null;
  const [timeLeft, setTimeLeft] = useState(totalTimeInSeconds);
  const [showResults, setShowResults] = useState(false);
  const [incorrectQuestions, setIncorrectQuestions] = useState([]);

  // Estados para navegación de preguntas
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showExplanation, setShowExplanation] = useState(false);
  const [reviewMode, setReviewMode] = useState(false);
  const [showDecisionTable, setShowDecisionTable] = useState(false);

  // Estado de historial de estudio
  const [studyHistory, setStudyHistory] = useState(() => {
    try {
      const saved = localStorage.getItem(`studyHistory_${title}`);
      return saved
        ? JSON.parse(saved)
        : {
            attempts: 0,
            correct: 0,
            questionHistory: {}
          };
    } catch {
      return {
        attempts: 0,
        correct: 0,
        questionHistory: {}
      };
    }
  });

  // ** Estados para los filtros: Año y Subtema **
  const [selectedYear, setSelectedYear] = useState('Todos');
  const [selectedSubtopic, setSelectedSubtopic] = useState('Todos'); 

  // Obtenemos array único de años y subtemas
  const years = [...new Set(questions.map((q) => q.year))];
  const subtopics = [...new Set(questions.map((q) => q.subtopic || 'Sin subtema'))];

  // Manejo del shuffle en modo simulación
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  useEffect(() => {
    if (simulationMode) {
      const shuffled = [...questions].sort(() => Math.random() - 0.5);
      setShuffledQuestions(shuffled);
    } else {
      setShuffledQuestions(questions);
    }
  }, [questions, simulationMode]);

  // ** Aplicamos el filtro de Año **
  const filteredByYear =
    selectedYear === 'Todos'
      ? shuffledQuestions
      : shuffledQuestions.filter((question) => question.year === selectedYear);

  // ** Aplicamos el filtro de Subtema sobre lo ya filtrado por año **
  const filteredQuestions = filteredByYear.filter((question) =>
    selectedSubtopic === 'Todos' ? true : question.subtopic === selectedSubtopic
  );

  // Ajustamos currentQuestion si el nuevo filtrado se queda sin preguntas en esa posición
  useEffect(() => {
    if (currentQuestion >= filteredQuestions.length) {
      setCurrentQuestion(0);
    }
  }, [filteredQuestions, currentQuestion]);

  // Guardamos el historial de estudio en localStorage
  useEffect(() => {
    try {
      localStorage.setItem(`studyHistory_${title}`, JSON.stringify(studyHistory));
    } catch (error) {
      console.error('Error saving to localStorage:', error);
    }
  }, [studyHistory, title]);

  // Temporizador para modo simulación
  useEffect(() => {
    let timer;
    if (simulationMode && !showResults && timeLeft !== null) {
      timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            finalizarSimulacro();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [simulationMode, showResults, timeLeft]);

  const formatTime = (seconds) => {
    const m = Math.floor(seconds / 60);
    const s = seconds % 60;
    return `${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
  };

  const currentQuestionData = filteredQuestions[currentQuestion];

  // Cada vez que cargamos pregunta nueva, limpiamos selección de respuesta, etc.
  useEffect(() => {
    setSelectedAnswer(null);
    setShowExplanation(false);
    setShowDecisionTable(false);
  }, [currentQuestionData]);

  // Lógica de responder pregunta
  const handleAnswer = (optionId) => {
    if (!currentQuestionData) return;

    const isCorrect = currentQuestionData.options.find((opt) => opt.id === optionId)?.correct;

    setSelectedAnswer(optionId);
    setShowExplanation(true);

    setStudyHistory((prev) => {
      const questionId = currentQuestionData.id.toString();
      const questionHistory = prev.questionHistory[questionId] || { attempts: 0, correct: 0 };

      return {
        ...prev,
        attempts: prev.attempts + 1,
        correct: prev.correct + (isCorrect ? 1 : 0),
        questionHistory: {
          ...prev.questionHistory,
          [questionId]: {
            attempts: questionHistory.attempts + 1,
            correct: questionHistory.correct + (isCorrect ? 1 : 0),
            lastAttempt: new Date().toISOString()
          }
        }
      };
    });

    if (!isCorrect) {
      setIncorrectQuestions((prev) => [...prev, currentQuestionData]);
    }
  };

  const nextQuestion = () => {
    if (simulationMode && currentQuestion === filteredQuestions.length - 1) {
      finalizarSimulacro();
    } else {
      setCurrentQuestion((prev) => (prev + 1) % filteredQuestions.length);
    }
  };

  const resetStudy = () => {
    setStudyHistory({
      attempts: 0,
      correct: 0,
      questionHistory: {}
    });
    setCurrentQuestion(0);
    setReviewMode(false);
    setIncorrectQuestions([]);
    setShowResults(false);
    setTimeLeft(simulationMode ? simulationTime * 60 : null);
  };

  const finalizarSimulacro = () => {
    setShowResults(true);
  };

  // Mostrar resultados (sólo en modo simulación)
  if (showResults && simulationMode) {
    const correctCount = filteredQuestions.length - incorrectQuestions.length;

    // Errores por sistema
    const errorsByOrganSystem = {};
    incorrectQuestions.forEach((q) => {
      const os = q.organsystem || 'Desconocido';
      if (!errorsByOrganSystem[os]) {
        errorsByOrganSystem[os] = 0;
      }
      errorsByOrganSystem[os] += 1;
    });

    // Errores por subtema
    const errorsBySubtopic = {};
    incorrectQuestions.forEach((q) => {
      const st = q.subtopic || 'Subtema desconocido';
      if (!errorsBySubtopic[st]) {
        errorsBySubtopic[st] = 0;
      }
      errorsBySubtopic[st] += 1;
    });

    // Total de preguntas por subtema (todas las preguntas, no sólo las filtradas)
    const totalBySubtopic = {};
    questions.forEach((q) => {
      const st = q.subtopic || 'Subtema desconocido';
      if (!totalBySubtopic[st]) {
        totalBySubtopic[st] = 0;
      }
      totalBySubtopic[st] += 1;
    });

    const resultsBySubtopic = Object.keys(totalBySubtopic).map((st) => {
      const total = totalBySubtopic[st];
      const errors = errorsBySubtopic[st] || 0;
      const correct = total - errors;
      return { subtopic: st, total, correct, errors };
    });

    return (
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">Resultados del Simulacro</h2>
        <p className="mb-4 text-center">
          Área: {subjectName} - Tema: {topicName}
        </p>
        <p className="text-center mb-4">Preguntas respondidas: {filteredQuestions.length}</p>
        <p className="text-center mb-4 text-green-700 font-bold">Correctas: {correctCount}</p>
        <p className="text-center mb-4 text-red-700 font-bold">Incorrectas: {incorrectQuestions.length}</p>

        {/* Errores por Órgano/Sistema */}
        <h3 className="text-xl font-bold mb-2">Temas/Subtemas con mayor número de errores (Órgano/Sistema):</h3>
        <table className="w-full border-collapse mb-4">
          <thead>
            <tr>
              <th className="border-b-2 border-gray-300 p-2">Órgano/Sistema</th>
              <th className="border-b-2 border-gray-300 p-2">Errores</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(errorsByOrganSystem).map((key) => (
              <tr key={key}>
                <td className="border-b border-gray-200 p-2">{key}</td>
                <td className="border-b border-gray-200 p-2">{errorsByOrganSystem[key]}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Resultados por Subtema */}
        <h3 className="text-xl font-bold mb-2">Resultados por Subtema:</h3>
        <table className="w-full border-collapse mb-4">
          <thead>
            <tr>
              <th className="border-b-2 border-gray-300 p-2">Subtema</th>
              <th className="border-b-2 border-gray-300 p-2">Total Preguntas</th>
              <th className="border-b-2 border-gray-300 p-2">Correctas</th>
              <th className="border-b-2 border-gray-300 p-2">Incorrectas</th>
            </tr>
          </thead>
          <tbody>
            {resultsBySubtopic.map(({ subtopic, total, correct, errors }) => (
              <tr key={subtopic}>
                <td className="border-b border-gray-200 p-2">{subtopic}</td>
                <td className="border-b border-gray-200 p-2">{total}</td>
                <td className="border-b border-gray-200 p-2 text-green-600 font-bold">{correct}</td>
                <td className="border-b border-gray-200 p-2 text-red-600 font-bold">{errors}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="mb-4">
          <p className="mb-2 font-semibold">Sugerencias de Estudio:</p>
          <ul className="list-disc list-inside text-gray-700">
            <li>Repasa las tablas y esquemas relacionados a {topicName}.</li>
            <li>Vuelve a intentarlo, enfocándote en las preguntas falladas.</li>
            <li>Utiliza la función de tabla de decisión rápida si está disponible.</li>
          </ul>
        </div>

        <button
          onClick={resetStudy}
          className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 font-semibold"
        >
          Reiniciar
        </button>
        <button
          onClick={onBack}
          className="w-full mt-2 px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 font-semibold"
        >
          Volver al Menú de Temas
        </button>
      </div>
    );
  }

  // Si no hay preguntas en el filtro actual
  if (!filteredQuestions || filteredQuestions.length === 0 || !currentQuestionData) {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <p className="text-red-600">No hay preguntas disponibles para los filtros aplicados.</p>
          <button
            onClick={() => {
              setSelectedYear('Todos');
              setSelectedSubtopic('Todos');
            }}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Ver Todas las Preguntas
          </button>
        </div>
      </div>
    );
  }

  // Vista principal
  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <button
          onClick={onBack}
          className="mb-4 px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
        >
          Volver al Menú de Temas
        </button>

        <div className="mb-6">
          <h1 className="text-2xl font-bold text-center text-gray-800 mb-2">
            {title}
          </h1>
          <div className="flex justify-between items-center flex-wrap gap-2">
            <div className="text-sm">
              <span className="font-medium">Intentos totales: </span>
              {studyHistory.attempts}
            </div>
            <div className="text-sm text-green-600">
              <span className="font-medium">Correctas (histórico): </span>
              {studyHistory.correct} (
              {studyHistory.attempts > 0
                ? Math.round((studyHistory.correct / studyHistory.attempts) * 100)
                : 0}
              %)
            </div>
            <button
              onClick={resetStudy}
              className="px-4 py-2 text-sm bg-red-500 text-white rounded-lg hover:bg-red-600"
            >
              Reiniciar
            </button>
            <button
              onClick={() => setReviewMode(!reviewMode)}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              {reviewMode ? 'Modo Práctica' : 'Ver Estadísticas'}
            </button>
          </div>

          {/* Modo simulación: temporizador */}
          {simulationMode && (
            <div className="mt-4 text-center">
              <p className="font-bold text-red-600">
                Tiempo restante: {formatTime(timeLeft)}
              </p>
              <div className="w-full bg-gray-200 h-4 rounded">
                <div
                  className="bg-red-500 h-4 rounded"
                  style={{
                    width: `${(timeLeft / (simulationTime * 60)) * 100}%`
                  }}
                ></div>
              </div>
            </div>
          )}
        </div>

        {/* Filtro por Año (si NO estamos en modo simulación) */}
        {!simulationMode && (
          <div className="mb-6">
            <label htmlFor="year-select" className="block text-gray-700 font-medium mb-2">
              Filtrar por Año:
            </label>
            <select
              id="year-select"
              value={selectedYear}
              onChange={(e) => {
                setSelectedYear(e.target.value);
                setCurrentQuestion(0);
              }}
              className="w-full p-2 border border-gray-300 rounded-lg"
            >
              <option value="Todos">Todos</option>
              {years.sort().map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Filtro por Subtema (si NO estamos en modo simulación) */}
        {!simulationMode && (
          <div className="mb-6">
            <label htmlFor="subtopic-select" className="block text-gray-700 font-medium mb-2">
              Filtrar por Subtema:
            </label>
            <select
              id="subtopic-select"
              value={selectedSubtopic}
              onChange={(e) => {
                setSelectedSubtopic(e.target.value);
                setCurrentQuestion(0);
              }}
              className="w-full p-2 border border-gray-300 rounded-lg"
            >
              <option value="Todos">Todos</option>
              {subtopics.map((subt) => (
                <option key={subt} value={subt}>
                  {subt}
                </option>
              ))}
            </select>
          </div>
        )}

        {!reviewMode ? (
          <>
            {/* Navegación de preguntas (solo si no estamos en simulacro) */}
            {!simulationMode && (
              <QuestionNavigation
                questions={filteredQuestions}
                currentQuestion={currentQuestion}
                setCurrentQuestion={(index) => {
                  setCurrentQuestion(index);
                }}
              />
            )}

            {/* Contenido de la pregunta actual */}
            <div className="mb-6">
              <div className="flex items-start gap-2 mb-4">
                <BookOpen className="w-6 h-6 text-blue-500 flex-shrink-0 mt-1" />
                <div>
                  <div className="text-sm text-gray-500 mb-2">
                    RM {currentQuestionData.year}
                  </div>
                  <p className="text-gray-800">{currentQuestionData.case}</p>
                  <p className="font-medium mt-2">{currentQuestionData.question}</p>
                </div>
              </div>

              <div className="space-y-3">
                {currentQuestionData.options.map((option) => (
                  <button
                    key={option.id}
                    onClick={() => !showExplanation && handleAnswer(option.id)}
                    disabled={showExplanation}
                    className={`w-full p-4 text-left rounded-lg transition-all ${
                      !selectedAnswer
                        ? 'hover:bg-gray-50 border border-gray-200'
                        : option.correct
                        ? 'bg-green-50 border-green-500'
                        : option.id === selectedAnswer
                        ? 'bg-red-50 border-red-500'
                        : 'border border-gray-200'
                    }`}
                  >
                    <div className="flex items-center gap-2">
                      {showExplanation && option.correct && (
                        <Check className="w-5 h-5 text-green-500" />
                      )}
                      {showExplanation && option.id === selectedAnswer && !option.correct && (
                        <X className="w-5 h-5 text-red-500" />
                      )}
                      <span className="font-medium">{option.id})</span>
                      <span>{option.text}</span>
                    </div>
                  </button>
                ))}
              </div>
            </div>

            {showExplanation && (
              <div className="mt-6 p-4 bg-blue-50 rounded-lg">
                <h3 className="font-bold text-blue-800 mb-2">Explicación:</h3>
                <p className="text-blue-900 mb-4">{currentQuestionData.explanation}</p>

                <h4 className="font-bold text-blue-800 mb-2">Puntos Clave:</h4>
                <ul className="list-disc list-inside text-blue-900">
                  {currentQuestionData.keyPoints.map((point, index) => (
                    <li key={index}>{point}</li>
                  ))}
                </ul>

                {DecisionTableComponents && DecisionTableComponents.length > 0 && (
                  <>
                    <button
                      onClick={() => setShowDecisionTable(!showDecisionTable)}
                      className="w-full mt-4 p-3 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
                    >
                      {showDecisionTable
                        ? 'Ocultar Tabla(s) de Decisión Rápida'
                        : 'Ver Tabla(s) de Decisión Rápida'}
                    </button>
                    {showDecisionTable &&
                      DecisionTableComponents.map((Component, index) => (
                        <div key={index} className="mt-4">
                          <Component />
                        </div>
                      ))}
                  </>
                )}

                <button
                  onClick={nextQuestion}
                  className="w-full mt-4 p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                  {simulationMode && currentQuestion === filteredQuestions.length - 1
                    ? 'Finalizar Simulacro'
                    : 'Siguiente Pregunta'}
                </button>
              </div>
            )}
          </>
        ) : (
          // Vista de estadísticas de cada pregunta
          <div className="space-y-4">
            <h2 className="text-xl font-bold text-gray-800 mb-4">Estadísticas por Pregunta</h2>
            {questions.map((question) => {
              const stats =
                studyHistory.questionHistory[question.id.toString()] || {
                  attempts: 0,
                  correct: 0
                };
              const effectiveness =
                stats.attempts > 0
                  ? Math.round((stats.correct / stats.attempts) * 100)
                  : 0;

              return (
                <div key={question.id} className="p-4 bg-gray-50 rounded-lg">
                  <div className="flex justify-between items-start mb-2">
                    <div className="font-medium">
                      Pregunta {question.id} (RM {question.year})
                    </div>
                    <div
                      className={`text-sm ${
                        effectiveness >= 70
                          ? 'text-green-600'
                          : effectiveness >= 40
                          ? 'text-yellow-600'
                          : 'text-red-600'
                      }`}
                    >
                      {effectiveness}% de efectividad
                    </div>
                  </div>
                  <p className="text-sm text-gray-600 mb-2">
                    Intentos: {stats.attempts} | Correctas: {stats.correct}
                  </p>
                  {stats.lastAttempt && (
                    <p className="text-xs text-gray-500">
                      Último intento: {new Date(stats.lastAttempt).toLocaleDateString()}
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

// Componente de navegación (índices de pregunta)
function QuestionNavigation({ questions, currentQuestion, setCurrentQuestion }) {
  return (
    <div className="mb-6">
      <h2 className="text-lg font-bold text-gray-800 mb-2">Navegación de Preguntas</h2>
      <div className="flex flex-wrap gap-2">
        {questions.map((question, index) => (
          <button
            key={question.id}
            onClick={() => setCurrentQuestion(index)}
            className={`px-3 py-2 rounded-lg border ${
              index === currentQuestion
                ? 'bg-blue-500 text-white border-blue-500'
                : 'bg-white text-gray-800 border-gray-300 hover:bg-gray-100'
            }`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Quiz;