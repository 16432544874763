const ruidosCardiacoscases = [
    {
      id: 1,
      year: '2024 A',
      organsystem: 'Cardiovascular',
      subtopic: 'Ruidos S3', // Nuevo campo de subtema
      case: 'Niños y adultos jóvenes con distensión ventricular súbita durante el llenado rápido',
      question: 'En niños y adultos jóvenes, la distensión ventricular súbita en la fase de llenado rápido, corresponde al ...... ruido cardiaco.',
      options: [
        { id: 'A', text: '3°', correct: true },
        { id: 'B', text: '1°', correct: false },
        { id: 'C', text: '4°', correct: false },
        { id: 'D', text: '2°', correct: false }
      ],
      explanation: 'El tercer ruido cardiaco (S3) aparece por la distensión súbita del ventrículo durante el llenado rápido.',
      keyPoints: [
        'S3 asociado a llenado ventricular rápido',
        'Distensión súbita del ventrículo',
        'Común en niños y adultos jóvenes',
        'Ritmo de galope'
      ]
    },
    {
      id: 2,
      year: '2023 A',
      organsystem: 'Cardiovascular',
      subtopic: 'Ruidos S1',
      case: 'Cierre de las válvulas aurículoventriculares al inicio de la sístole',
      question: '¿Cuál es el ruido cardiaco que es causado por el flujo turbulento asociado al cierre de las válvulas auriculoventriculares al comienzo de la sístole?',
      options: [
        { id: 'A', text: 'S2', correct: false },
        { id: 'B', text: 'S4', correct: false },
        { id: 'C', text: 'S3', correct: false },
        { id: 'D', text: 'S1', correct: true }
      ],
      explanation: 'El primer ruido cardiaco (S1) se produce por el cierre de las válvulas mitral y tricúspide al inicio de la sístole.',
      keyPoints: [
        'S1: cierre de válvulas AV',
        'Inicio de la sístole',
        'Flujo turbulento por cierre mitral y tricúspide',
        'Ruido más intenso en ápex'
      ]
    },
    {
      id: 3,
      year: '2025',
      organsystem: 'Cardiovascular',
      subtopic: 'Ruidos S1',
      case: '',
      question: 'El primer ruido cardíaco (S1) se produce principalmente por:',
      options: [
        { id: 'A', text: 'Cierre de las válvulas semilunares (aórtica y pulmonar)', correct: false },
        { id: 'B', text: 'Llenado rápido del ventrículo izquierdo en diástole', correct: false },
        { id: 'C', text: 'Cierre de las válvulas auriculoventriculares (mitral y tricúspide)', correct: true },
        { id: 'D', text: 'Contracción auricular sobre un ventrículo rígido', correct: false }
      ],
      explanation: 'El S1 se genera principalmente por el cierre de las válvulas mitral y tricúspide al inicio de la sístole ventricular.',
      keyPoints: [
        'S1: Cierre AV (mitral y tricúspide)',
        'Marca el inicio de la sístole ventricular'
      ]
    },
    {
      id: 4,
      year: '2025',
      organsystem: 'Cardiovascular',
      subtopic: 'Ruidos S1',
      case: '',
      question: '¿En qué momento del ciclo cardíaco aparece el S1?',
      options: [
        { id: 'A', text: 'Justo al finalizar la diástole y antes de la contracción auricular', correct: false },
        { id: 'B', text: 'Al inicio de la sístole ventricular', correct: true },
        { id: 'C', text: 'A la mitad de la diástole temprana', correct: false },
        { id: 'D', text: 'Después del cierre de las válvulas semilunares', correct: false }
      ],
      explanation: 'El S1 marca el inicio de la sístole ventricular, cuando se cierran las válvulas AV.',
      keyPoints: [
        'S1 coincide con el pulso carotídeo',
        'Se relaciona con el cierre de válvulas AV'
      ]
    },
    {
      id: 5,
      year: '2025',
      organsystem: 'Cardiovascular',
      subtopic: 'Ruidos S2',
      case: '',
      question: 'El segundo ruido cardíaco (S2) se produce por el:',
      options: [
        { id: 'A', text: 'Cierre de las válvulas auriculoventriculares (mitral y tricúspide)', correct: false },
        { id: 'B', text: 'Cierre de las válvulas semilunares (aórtica y pulmonar)', correct: true },
        { id: 'C', text: 'Apertura de la válvula mitral', correct: false },
        { id: 'D', text: 'Llenado rápido ventricular', correct: false }
      ],
      explanation: 'El S2 se genera por el cierre de las válvulas aórtica y pulmonar, marcando el inicio de la diástole.',
      keyPoints: [
        'S2: Cierre de válvulas semilunares',
        'Marca el inicio de la diástole'
      ]
    },
    {
      id: 6,
      year: '2025',
      organsystem: 'Cardiovascular',
      subtopic: 'Ruidos S2',
      case: '',
      question: '¿En qué momento del ciclo cardíaco ocurre el segundo ruido (S2)?',
      options: [
        { id: 'A', text: 'Al final de la diástole, antes de la sístole', correct: false },
        { id: 'B', text: 'Al inicio de la sístole', correct: false },
        { id: 'C', text: 'Al inicio de la diástole', correct: true },
        { id: 'D', text: 'En la fase de contracción isovolumétrica', correct: false }
      ],
      explanation: 'El S2 ocurre al inicio de la diástole, cuando las válvulas aórtica y pulmonar se cierran.',
      keyPoints: [
        'S2: Inicio de diástole',
        'Cierre aórtico y pulmonar'
      ]
    },
    {
      id: 7,
      year: '2025',
      organsystem: 'Cardiovascular',
      subtopic: 'Ruidos S2',
      case: '',
      question: 'El desdoblamiento fisiológico del S2 se percibe mejor durante:',
      options: [
        { id: 'A', text: 'La espiración', correct: false },
        { id: 'B', text: 'La inspiración', correct: true },
        { id: 'C', text: 'La apnea prolongada', correct: false },
        { id: 'D', text: 'No existe desdoblamiento fisiológico de S2', correct: false }
      ],
      explanation: 'Durante la inspiración, el retorno venoso derecho aumenta, retrasando el cierre de la válvula pulmonar.',
      keyPoints: [
        'Desdoblamiento fisiológico de S2: más evidente en inspiración',
        'Retorno venoso derecho aumentado retrasa cierre pulmonar'
      ]
    },
    {
      id: 8,
      year: '2025',
      organsystem: 'Cardiovascular',
      subtopic: 'Ruidos S2',
      case: '',
      question: '¿Cuál es la causa del desdoblamiento fisiológico de S2 durante la inspiración?',
      options: [
        { id: 'A', text: 'Acortamiento del llenado ventricular izquierdo', correct: false },
        { id: 'B', text: 'Cierre precoz de la válvula aórtica', correct: false },
        { id: 'C', text: 'Aumento del retorno venoso a la aurícula derecha prolongando el vaciamiento del ventrículo derecho y retrasando el cierre pulmonar', correct: true },
        { id: 'D', text: 'Disminución del flujo pulmonar con cierre temprano de la válvula pulmonar', correct: false }
      ],
      explanation: 'La inspiración incrementa el retorno venoso derecho, prolongando la eyección del VD y retrasando el cierre de la válvula pulmonar.',
      keyPoints: [
        'Inspiración → más retorno venoso derecho',
        'Prolonga sístole del VD',
        'Retrasa cierre pulmonar (P2)'
      ]
    },
    {
      id: 9,
      year: '2025',
      organsystem: 'Cardiovascular',
      subtopic: 'Ruidos S2',
      case: '',
      question: 'Un desdoblamiento fijo de S2 puede ser un hallazgo clásico en:',
      options: [
        { id: 'A', text: 'Estenosis mitral', correct: false },
        { id: 'B', text: 'Comunicación interauricular (CIA)', correct: true },
        { id: 'C', text: 'Bloqueo AV de primer grado', correct: false },
        { id: 'D', text: 'Hipertensión arterial sistémica', correct: false }
      ],
      explanation: 'La CIA produce un aumento constante del flujo en el lado derecho del corazón, manteniendo un retraso fijo en el cierre de la válvula pulmonar.',
      keyPoints: [
        'CIA → Desdoblamiento fijo de S2',
        'Aumento flujo derecho prolonga cierre pulmonar continuamente'
      ]
    }
  ];
  
  export default ruidosCardiacoscases;