import React, { useState } from 'react';
import './FlashcardCell.css'; // Asegúrate de que el path es el correcto

function RuidosCardiacosTable() {
  const [expandedRows, setExpandedRows] = useState([]);
  const [hiddenCells, setHiddenCells] = useState({});
  const [hiddenColumns, setHiddenColumns] = useState({});
  const [revealedText, setRevealedText] = useState('');

  const toggleRow = (index) => {
    setExpandedRows((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const tableData = [
    {
      ruido: 'S1',
      momentoCiclo: 'Inicio de la sístole',
      valvulasEvento: 'Cierre AV (mitral, tricúspide)',
      causaFisiologica:
        'Cierre de válvulas AV por aumento de presión ventricular al iniciar la sístole',
      normalPatologico: 'Normal',
      sitioAuscultacion: 'Área mitral (5° EIC línea MCL izq.)',
      frases: ['S1', 'cierre de válvulas AV', 'inicio sístole']
    },
    {
      ruido: 'S2',
      momentoCiclo: 'Inicio de la diástole',
      valvulasEvento: 'Cierre semilunares (aórtica, pulmonar)',
      causaFisiologica:
        'Cierre de válvulas semilunares al disminuir presión en ventrículos',
      normalPatologico: 'Normal',
      sitioAuscultacion: 'Área aórtica y pulmonar (2° EIC)',
      frases: ['S2', 'cierre semilunares', 'inicio diástole']
    },
    {
      ruido: 'S3',
      momentoCiclo: 'Comienzo de la diástole temprana (llenado rápido)',
      valvulasEvento: '-',
      causaFisiologica:
        'Vibración de paredes ventriculares por llenado súbito',
      normalPatologico: 'Fisiológico en jóvenes, patológico en adultos mayores (ICC)',
      sitioAuscultacion: 'Ápice con campana, en decúbito lateral izquierdo',
      frases: ['S3', 'llenado rápido', 'ICC']
    },
    {
      ruido: 'S4',
      momentoCiclo: 'Final de la diástole (antes de S1, contracción auricular)',
      valvulasEvento: '-',
      causaFisiologica:
        'Contracción auricular contra ventrículo rígido',
      normalPatologico: 'Generalmente patológico (HVI, HTA)',
      sitioAuscultacion: 'Ápice con campana, en decúbito lateral izquierdo',
      frases: ['S4', 'contracción auricular', 'HVI', 'HTA']
    },
  ];

  const columnDisplayNames = {
    ruido: 'Ruido',
    momentoCiclo: 'Momento en el Ciclo Cardíaco',
    valvulasEvento: 'Válvulas / Evento',
    causaFisiologica: 'Causa Fisiológica',
    normalPatologico: 'Normal / Patológico',
    sitioAuscultacion: 'Sitio de Mejor Auscultación',
  };

  const columns = Object.keys(columnDisplayNames);

  const toggleColumn = (columnKey) => {
    setHiddenColumns((prevState) => {
      const newHiddenColumns = {
        ...prevState,
        [columnKey]: !prevState[columnKey],
      };

      const newHiddenCells = { ...hiddenCells };
      tableData.forEach((item, idx) => {
        const cellKey = `${idx}-${columnKey}`;
        if (newHiddenColumns[columnKey]) {
          newHiddenCells[cellKey] = true;
        } else {
          delete newHiddenCells[cellKey];
        }
      });

      setHiddenCells(newHiddenCells);
      return newHiddenColumns;
    });
  };

  const toggleCell = (rowIdx, fieldKey) => {
    const cellKey = `${rowIdx}-${fieldKey}`;
    setHiddenCells((prevState) => ({
      ...prevState,
      [cellKey]: !prevState[cellKey],
    }));
  };

  const toggleAllCells = () => {
    const areAllCellsVisible = Object.keys(hiddenCells).length === 0;

    if (areAllCellsVisible) {
      const newHiddenCells = {};
      tableData.forEach((item, idx) => {
        columns.forEach((colKey) => {
          if (colKey !== 'ruido') {
            const cellKey = `${idx}-${colKey}`;
            newHiddenCells[cellKey] = true;
          }
        });
      });
      setHiddenCells(newHiddenCells);
    } else {
      setHiddenCells({});
    }
  };

  const handleRevealText = (text, rowIdx, fieldKey) => {
    if (revealedText === text) {
      setRevealedText('');
    } else {
      setRevealedText(text);
    }

    const cellKey = `${rowIdx}-${fieldKey}`;
    setHiddenCells((prevHiddenCells) => {
      const newState = { ...prevHiddenCells };
      if (newState[cellKey]) {
        delete newState[cellKey];
      }
      return newState;
    });
  };

  return (
    <div className="overflow-x-auto mt-4 p-4 bg-white shadow-lg rounded-md">
      <h4 className="font-bold text-blue-800 mb-4 text-center text-2xl">
        Tabla Resumen de los Ruidos Cardíacos
      </h4>

      <div className="mb-4 text-center">
        <button
          onClick={toggleAllCells}
          className="mb-2 px-3 py-1 rounded bg-green-600 text-white hover:bg-green-700 transition font-semibold"
        >
          {Object.keys(hiddenCells).length === 0
            ? 'Ocultar toda la información'
            : 'Mostrar toda la información'}
        </button>
      </div>

      <div className="mb-6 text-center">
        <span className="font-bold mr-2">Mostrar/Ocultar Columnas:</span>
        {columns.map((columnKey) => (
          <button
            key={columnKey}
            onClick={() => toggleColumn(columnKey)}
            className="mr-2 mb-2 px-3 py-1 rounded bg-blue-500 text-white hover:bg-blue-600 transition font-semibold"
          >
            {hiddenColumns[columnKey]
              ? `Mostrar ${columnDisplayNames[columnKey]}`
              : `Ocultar ${columnDisplayNames[columnKey]}`}
          </button>
        ))}
      </div>

      <table className="w-full border-collapse">
        <thead>
          <tr>
            <th className="border-b-2 border-gray-300 bg-blue-100 text-center p-3 font-semibold">Acción</th>
            {columns.map((columnKey) => (
              !hiddenColumns[columnKey] && (
                <th
                  key={columnKey}
                  className="border-b-2 border-gray-300 bg-blue-100 text-center p-3 font-semibold text-gray-700"
                  style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                >
                  {columnDisplayNames[columnKey]}
                </th>
              )
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {tableData.map((item, idx) => (
            <React.Fragment key={idx}>
              <tr className="transition duration-200 hover:bg-gray-50">
                <td className="p-3 align-top text-center border-b border-gray-200">
                  <button
                    onClick={() => toggleRow(idx)}
                    className="text-blue-600 underline font-semibold hover:text-blue-800"
                  >
                    {expandedRows.includes(idx) ? 'Ocultar' : 'Mostrar'}
                  </button>
                </td>
                {columns.map((colKey) => {
                  if (hiddenColumns[colKey]) return null;
                  return (
                    <td
                      key={colKey}
                      className="p-3 align-top border-b border-gray-200"
                      style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    >
                      <div
                        className={`flashcard-cell ${
                          hiddenCells[`${idx}-${colKey}`] ? 'flipped' : ''
                        }`}
                      >
                        <div className="flashcard-cell-inner">
                          <div
                            className="flashcard-cell-front rounded-md shadow-md bg-white p-3"
                            onClick={() => toggleCell(idx, colKey)}
                          >
                            {Array.isArray(item[colKey])
                              ? item[colKey].join(', ')
                              : item[colKey]}
                          </div>
                          <div
                            className="flashcard-cell-back rounded-md shadow-md bg-gray-50 p-3"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <button
                              className="text-blue-600 underline font-semibold hover:text-blue-800"
                              onClick={() =>
                                handleRevealText(
                                  Array.isArray(item[colKey])
                                    ? `${columnDisplayNames[colKey]}: ${item[colKey].join(', ')}`
                                    : item[colKey],
                                  idx,
                                  colKey
                                )
                              }
                            >
                              {revealedText ===
                              (Array.isArray(item[colKey])
                                ? `${columnDisplayNames[colKey]}: ${item[colKey].join(', ')}`
                                : item[colKey])
                                ? 'Ocultar'
                                : 'Mostrar'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  );
                })}
              </tr>

              {expandedRows.includes(idx) && (
                <tr className="transition duration-200 bg-gray-50">
                  <td className="p-3 border-b border-gray-200"></td>
                  <td
                    className="p-3 border-b border-gray-200"
                    colSpan={columns.filter((c) => !hiddenColumns[c]).length}
                    style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  >
                    <strong>Detalles Adicionales (frases):</strong>
                    <ul className="list-disc list-inside mt-2">
                      {item.frases.map((phrase, index) => (
                        <li key={index}>{phrase}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {revealedText && (
        <div className="mt-6 p-4 border border-gray-300 bg-gray-100 rounded-md shadow-sm">
          <h5 className="font-bold mb-2 text-gray-800">Texto Mostrado:</h5>
          <p className="text-gray-700">{revealedText}</p>
        </div>
      )}
    </div>
  );
}

export default RuidosCardiacosTable;