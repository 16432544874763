import React, { useState } from 'react';
import './FlashcardCell.css'; // Ajusta la ruta si es necesario

function Tabladeruidoscardiacos2() {
  const [expandedRows, setExpandedRows] = useState([]);
  const [hiddenCells, setHiddenCells] = useState({});
  const [hiddenColumns, setHiddenColumns] = useState({});
  const [revealedText, setRevealedText] = useState('');

  const toggleRow = (index) => {
    setExpandedRows((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  /**
   * DATOS DE LA TABLA
   * Se incluyen las columnas solicitadas:
   * - Ruido (S)
   * - Momento
   * - Válvulas / Evento
   * - Lo que hay que saber SÍ o SÍ
   * - Conceptos Clave Preguntables
   */
  const tableData = [
    {
      ruido: 'S1',
      momento: 'Inicio de la sístole (justo después del QRS)',
      valvulasEvento: 'Cierre de mitral y tricúspide',
      infoClaves: [
        '- Indica el comienzo de la sístole',
        '- Se ausculta mejor en el ápex con el diafragma',
        '- Es más intenso con estados hiperdinámicos (fiebre, ejercicio)',
      ],
      conceptosClave: [
        '- Diferenciación con S2: S1 coincide con el pulso carotídeo',
        '- Variaciones en intensidad: Disminuido en insuficiencia mitral severa',
        '- Relación con el intervalo PR: Un PR corto puede aumentar la intensidad del S1; un PR largo la disminuye',
      ],
    },
    {
      ruido: 'S2',
      momento: 'Inicio de la diástole (justo después de la onda T)',
      valvulasEvento: 'Cierre de aórtica (A2) y pulmonar (P2)',
      infoClaves: [
        '- Indica el comienzo de la diástole',
        '- Su desdoblamiento fisiológico se escucha mejor en foco pulmonar (2° EIC línea paraesternal izq.)',
      ],
      conceptosClave: [
        '- Desdoblamiento de S2: fisiológico vs. patológico',
        '- A2 y P2: en la inspiración se retrasa el cierre de la válvula pulmonar (P2)',
        '- Anomalías: desdoblamiento fijo (CIA), desdoblamiento paradójico (estenosis aórtica, BRI)',
      ],
    },
    {
      ruido: 'S3',
      momento: 'Llenado rápido ventricular (inmediatamente después de S2)',
      valvulasEvento:
        'Vibración de las paredes ventriculares por la entrada rápida de sangre',
      infoClaves: [
        '- Normal en niños, adultos jóvenes y embarazadas (ventrículo más complaciente)',
        '- En adultos mayores sugiere IC o sobrecarga de volumen (patológico)',
      ],
      conceptosClave: [
        '- Se llama “galope ventricular” cuando es patológico',
        '- Localización de auscultación: generalmente en el ápex con la campana del estetoscopio',
        '- Mecanismo: sobrecarga de volumen (IC, regurgitación mitral/aórtica)',
      ],
    },
    {
      ruido: 'S4',
      momento: 'Final de la diástole (justo antes de S1)',
      valvulasEvento:
        'Contracción auricular contra un ventrículo rígido o no distensible',
      infoClaves: [
        '- Se llama también “galope auricular”',
        '- Siempre considerado patológico o al menos anormal (Hipertrofia, HTA, estenosis aórtica)',
      ],
      conceptosClave: [
        '- Mecanismo: la aurícula se contrae contra una pared ventricular rígida (p. ej., ventrículo izquierdo hipertrófico)',
        '- Se ausculta mejor en el ápex, en decúbito lateral izquierdo',
        '- Indicador de enfermedad cardíaca estructural',
      ],
    },
  ];

  /**
   * NOMENCLATURA DE COLUMNAS
   * "columnDisplayNames" asocia cada key del objeto tableData
   * con el nombre de la columna que se mostrará en la tabla.
   */
  const columnDisplayNames = {
    ruido: 'Ruido (S)',
    momento: 'Momento',
    valvulasEvento: 'Válvulas / Evento',
    infoClaves: 'Lo que hay que saber SÍ o SÍ',
    conceptosClave: 'Conceptos Clave Preguntables',
  };

  // Array con el orden de las columnas que se van a mostrar en la tabla
  const columns = Object.keys(columnDisplayNames);

  /**
   * FUNCIONES PARA MOSTRAR/OCULTAR COLUMNAS
   */
  const toggleColumn = (columnKey) => {
    setHiddenColumns((prevState) => {
      const newHiddenColumns = {
        ...prevState,
        [columnKey]: !prevState[columnKey],
      };

      const newHiddenCells = { ...hiddenCells };
      tableData.forEach((_, idx) => {
        const cellKey = `${idx}-${columnKey}`;
        // Si la columna se está ocultando, oculta también las celdas
        if (newHiddenColumns[columnKey]) {
          newHiddenCells[cellKey] = true;
        } else {
          delete newHiddenCells[cellKey];
        }
      });

      setHiddenCells(newHiddenCells);
      return newHiddenColumns;
    });
  };

  /**
   * FUNCIONES PARA MOSTRAR/OCULTAR CELDAS (MODO FLASHCARD)
   */
  const toggleCell = (rowIdx, fieldKey) => {
    const cellKey = `${rowIdx}-${fieldKey}`;
    setHiddenCells((prev) => ({
      ...prev,
      [cellKey]: !prev[cellKey],
    }));
  };

  const toggleAllCells = () => {
    const areAllCellsVisible = Object.keys(hiddenCells).length === 0;
    if (areAllCellsVisible) {
      // Oculta todas las celdas (menos la columna 'ruido', si deseas)
      const newHiddenCells = {};
      tableData.forEach((_, idx) => {
        columns.forEach((colKey) => {
          if (colKey !== 'ruido') {
            const cellKey = `${idx}-${colKey}`;
            newHiddenCells[cellKey] = true;
          }
        });
      });
      setHiddenCells(newHiddenCells);
    } else {
      // Muestra todas las celdas
      setHiddenCells({});
    }
  };

  /**
   * FUNCIONES PARA MOSTRAR EL TEXTO DE LA PARTE POSTERIOR DEL FLASHCARD
   */
  const handleRevealText = (text, rowIdx, fieldKey) => {
    if (revealedText === text) {
      setRevealedText('');
    } else {
      setRevealedText(text);
    }
    const cellKey = `${rowIdx}-${fieldKey}`;
    setHiddenCells((prevHiddenCells) => {
      const newState = { ...prevHiddenCells };
      // Si la celda estaba oculta, la mostramos
      if (newState[cellKey]) {
        delete newState[cellKey];
      }
      return newState;
    });
  };

  return (
    <div className="overflow-x-auto mt-4 p-4 bg-white shadow-lg rounded-md">
      <h4 className="font-bold text-blue-800 mb-4 text-center text-2xl">
        Tabla de Ruidos Cardíacos con funcionalidades
      </h4>

      {/* Botón para ocultar/mostrar todas las celdas */}
      <div className="mb-4 text-center">
        <button
          onClick={toggleAllCells}
          className="mb-2 px-3 py-1 rounded bg-green-600 text-white hover:bg-green-700 transition font-semibold"
        >
          {Object.keys(hiddenCells).length === 0
            ? 'Ocultar toda la información'
            : 'Mostrar toda la información'}
        </button>
      </div>

      {/* Botones para mostrar/ocultar columnas */}
      <div className="mb-6 text-center">
        <span className="font-bold mr-2">Mostrar/Ocultar Columnas:</span>
        {columns.map((columnKey) => (
          <button
            key={columnKey}
            onClick={() => toggleColumn(columnKey)}
            className="mr-2 mb-2 px-3 py-1 rounded bg-blue-500 text-white hover:bg-blue-600 transition font-semibold"
          >
            {hiddenColumns[columnKey]
              ? `Mostrar ${columnDisplayNames[columnKey]}`
              : `Ocultar ${columnDisplayNames[columnKey]}`}
          </button>
        ))}
      </div>

      {/* TABLA */}
      <table className="w-full border-collapse">
        <thead>
          <tr>
            {/* Columna para la acción de mostrar/ocultar filas (desplegar detalles) */}
            <th className="border-b-2 border-gray-300 bg-blue-100 text-center p-3 font-semibold">
              Acción
            </th>
            {/* Generar las cabeceras que no estén ocultas */}
            {columns.map(
              (columnKey) =>
                !hiddenColumns[columnKey] && (
                  <th
                    key={columnKey}
                    className="border-b-2 border-gray-300 bg-blue-100 text-center p-3 font-semibold text-gray-700"
                    style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  >
                    {columnDisplayNames[columnKey]}
                  </th>
                )
            )}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {tableData.map((item, idx) => (
            <React.Fragment key={idx}>
              <tr className="transition duration-200 hover:bg-gray-50">
                {/* Columna de Acción (botón Mostrar/Ocultar detalles) */}
                <td className="p-3 align-top text-center border-b border-gray-200">
                  <button
                    onClick={() => toggleRow(idx)}
                    className="text-blue-600 underline font-semibold hover:text-blue-800"
                  >
                    {expandedRows.includes(idx) ? 'Ocultar' : 'Mostrar'}
                  </button>
                </td>

                {/* Generar celdas para cada columna */}
                {columns.map((colKey) => {
                  if (hiddenColumns[colKey]) return null;

                  // El valor real que se mostrará en la celda
                  let cellValue = item[colKey];

                  // Si es un array (lo que hay que saber, conceptos clave), se une con saltos de línea o comas
                  if (Array.isArray(cellValue)) {
                    cellValue = cellValue.join('\n');
                  }

                  const cellKey = `${idx}-${colKey}`;

                  return (
                    <td
                      key={colKey}
                      className="p-3 align-top border-b border-gray-200"
                      style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} // "pre-wrap" para respetar los saltos de línea
                    >
                      <div
                        className={`flashcard-cell ${
                          hiddenCells[cellKey] ? 'flipped' : ''
                        }`}
                      >
                        <div className="flashcard-cell-inner">
                          {/* FRONT */}
                          <div
                            className="flashcard-cell-front rounded-md shadow-md bg-white p-3 cursor-pointer"
                            onClick={() => toggleCell(idx, colKey)}
                          >
                            {cellValue}
                          </div>

                          {/* BACK */}
                          <div
                            className="flashcard-cell-back rounded-md shadow-md bg-gray-50 p-3"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <button
                              className="text-blue-600 underline font-semibold hover:text-blue-800"
                              onClick={() =>
                                handleRevealText(cellValue, idx, colKey)
                              }
                            >
                              {revealedText === cellValue ? 'Ocultar' : 'Mostrar'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  );
                })}
              </tr>

              {/* Filas de Detalles Adicionales al desplegar (aquí como ejemplo, podríamos mostrar "extra info" si quieres) */}
              {expandedRows.includes(idx) && (
                <tr className="transition duration-200 bg-gray-50">
                  <td className="p-3 border-b border-gray-200"></td>
                  <td
                    className="p-3 border-b border-gray-200"
                    colSpan={columns.filter((c) => !hiddenColumns[c]).length}
                    style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  >
                    <strong>Detalles adicionales:</strong> Aquí podrías añadir
                    cualquier información extra si fuera necesario.
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {/* Contenedor para mostrar texto “revelado” de flashcards */}
      {revealedText && (
        <div className="mt-6 p-4 border border-gray-300 bg-gray-100 rounded-md shadow-sm">
          <h5 className="font-bold mb-2 text-gray-800">Texto Mostrado:</h5>
          <p className="text-gray-700">{revealedText}</p>
        </div>
      )}
    </div>
  );
}

export default Tabladeruidoscardiacos2;